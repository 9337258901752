<template>
  <v-container fluid>
    <PageTitle title="Home" />
    <!-- <v-alert text outlined color="deep-orange">
      <div class="text-caption grey--text">Verification Account in Progress</div>
      <div class="black--text">Your submission is under review, please wait within 4 days.</div>
    </v-alert> -->
    <v-row>
      <v-col md="3" class="d-flex flex-column">
        <v-dialog v-model="modals.topupOption" max-width="500" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="zipay"
              dark
              v-bind="attrs"
              v-on="on"
              block
              large
              class="mb-3"
            >
              Top Up
            </v-btn>
          </template>
          <v-card class="pa-10">
            <div class="d-flex justify-space-between">
              <v-card-title class="text-h5">
                Pilih Metode
              </v-card-title>
              <button @click="() => {
                modals.topupOption = false
                topupType = ''
                resetTopup()
              }">
                <v-icon large>
                  mdi-close
                </v-icon>
              </button>
            </div>
            <v-btn 
              block 
              color="zipay"
              large
              dark
              class="mb-3"
              @click="() => {
                modals.topupOption = false
                modals.topup = true
                topupType = 'static'
              }">
              Topup Static Open VA
            </v-btn>
            <v-btn 
              block 
              color="zipay"
              large
              dark
              class="mb-3"
              @click="() => {
                modals.topupOption = false
                modals.topup = true
                topupType = 'static-amount'
              }">
              Static VA with Amount
            </v-btn>
            <v-btn 
              block 
              color="zipay"
              large
              dark
              @click="() => {
                modals.topupOption = false
                modals.topup = true
                topupType = 'dynamic'
              }">
              Dynamic VA
            </v-btn>
          </v-card>
        </v-dialog>
        <v-dialog v-model="modals.withdraw" max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="zipay"
              dark
              v-bind="attrs"
              v-on="on"
              block
              large
            >
              Kirim
            </v-btn>
          </template>
          <v-card class="pa-10">
            <div class="d-flex justify-space-between">
              <v-card-title class="text-h5">
                Kirim
              </v-card-title>
              <button @click="() => {
                modals.withdraw = false
                resetCashout()
              }">
                <v-icon large>
                  mdi-close
                </v-icon>
              </button>
            </div>
            <v-card-text>
              Available Balance <span class="font-weight-black">{{ rupiahFormat(balance.availableBalance) }}</span>
            </v-card-text>
            <v-form v-model="wdValid">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="cashoutPayload.msisdn"
                      label="Masukkan No. Telepon"
                      required
                      outlined
                      hide-details="auto"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="cashoutPayload.amount"
                      label="Masukkan Nominal"
                      required
                      outlined
                      prefix="Rp"
                      hide-details="auto"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="account.bankId"
                      :items="cashboutBanks"
                      item-text="name"
                      item-value="idBank"
                      label="Pilih Bank"
                      outlined
                      hide-details="auto"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="account.number"
                      label="Masukkan Nomor Rekening"
                      required
                      outlined
                      append-icon="mdi-check"
                      hide-details="auto"
                      @click:append="checkInquiry"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-card elevation="2">
                      <v-card-subtitle>
                        Nama Rekening
                      </v-card-subtitle>
                      <v-card-title>
                        {{ account.holder ?? '-' }}
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn 
                      elevation="2" 
                      large color="zipay" 
                      @click="cashoutHandler"
                      :loading="loading"
                      :disabled="loading || !wdValid || account.holder === ''"
                    >
                      Process
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col>
            <v-card elevation="1">
              <v-card-text>Available Balance</v-card-text>
              <v-card-title class="text-h5 font-weight-bold black--text">
                {{ rupiahFormat(balance?.availableBalance) }}
              </v-card-title>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1">
              <v-card-text>Holding Balance</v-card-text>
              <v-card-title class="text-h5 font-weight-bold black--text">
                {{ rupiahFormat(balance?.holdingBalance) }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="modals.vaSuccess" max-width="500">
      <v-card outlined class="pa-10">
        <div class="text-center">
          <v-img src="@/assets/success.svg" width="50" class="mx-auto" />
          <h3 class="fw-bold">No. Va Telah Terbit!</h3>
        </div>
        <TrxSuccess />
        <v-card-actions>
          <v-btn elevation="2" color="zipay" class="mx-auto" @click="modals.vaSuccess = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modals.withdrawSuccess" max-width="500">
      <v-card outlined class="pa-10">
        <div class="text-center">
          <v-img src="@/assets/success.svg" width="50" class="mx-auto" />
          <h3 class="fw-bold mb-10">Pengiriman Berhasil!</h3>
          <p>Tarik saldo berhasil, mohon menunggu maksimal 2 hari untuk persetujuan.</p>
        </div>
        <withdrawSuccess />
        <v-card-actions>
          <v-btn elevation="2" class="mx-auto" color="zipay" @click="modals.withdrawSuccess = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.topup" max-width="500" persistent>
      <v-card class="pa-10">
        <div class="d-flex justify-space-between">
          <v-card-title class="text-h5">
            Top Up
          </v-card-title>
          <button @click="() => {
            modals.topup = false
            topupType = ''
            resetTopup()
          }">
            <v-icon large>
              mdi-close
            </v-icon>
          </button>
        </div>
        <v-form v-model="topupValid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="topupPayload.displayName"
                  label="Masukkan Display Name"
                  required
                  outlined
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" v-if="topupType === 'dynamic' || topupType === 'static-amount'">
                <v-text-field
                  v-model="topupPayload.amount"
                  label="Masukkan Nominal"
                  prefix="Rp"
                  required
                  outlined
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="topupPayload.bankShortCode"
                  :items="vaBanks"
                  item-text="bankName"
                  item-value="bankShortCode"
                  label="Pilih Bank"
                  outlined
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="topupPayload.msisdn"
                  label="Masukkan No. Telepon"
                  required
                  outlined
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="topupPayload.description"
                  label="Catatan"
                  required
                  outlined
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn 
                  @click="topupHandler" 
                  elevation="2" 
                  large color="zipay" 
                  :loading="loading"
                  :disabled="loading || !topupValid"
                >
                  Process
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PageTitle from "@/components/molecules/PageTitle.vue";
import TrxSuccess from "@/components/molecules/TrxSuccess.vue";
import { mapActions, mapGetters } from "vuex";
import { api, api_prod } from "@/libs/api";
import withdrawSuccess from "@/components/molecules/withdrawSuccess.vue";
import currency from "@/libs/currency";

export default {
  mixins: [currency],
  components: { 
    PageTitle,
    TrxSuccess,
    withdrawSuccess
  },
  data() {
    return {
      modals: {
        withdraw: false,
        topup: false,
        vaSuccess: false,
        withdrawSuccess: false,
        topupOption: false
      },
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      account: {
        bankId: '',
        number: '',
        holder: ''
      },
      cashoutPayload: {
        amount: 0,
        msisdn: '',
        bankName: '',
        adminFee: 0,
      },
      topupPayload: {
        amount: 0,
        bankShortCode: '',
        description: '',
        msisdn: '',
        callbackUrl: 'https://google.com',
        displayName: '',
        customMerchantId: +new Date,
      },
      loading: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },
      topupValid: false,
      wdValid: false,
      topupType: ''
    }
  },
  computed: {
    ...mapGetters('virtualAccountModule', {
      vaBanks: 'banks'
    }),
    ...mapGetters('cashoutModule', {
      cashboutBanks: 'banks'
    }),
    ...mapGetters('authModule', ['balance']),
  },
  methods: {
    ...mapActions('virtualAccountModule', {
      getVABanks: 'getBanks',
      setVA: 'setVA'
    }),
    ...mapActions('cashoutModule', {
      getCashoutBanks: 'getBanks',
      withdraw: 'withdraw'
    }),
    async topupHandler() {
      let url = ''

      if (this.topupType === 'dynamic') {
        url = '/va/generate/payment'
      } else if (this.topupType === 'static') {
        url = '/va/generate/open'

        delete this.topupPayload.amount
        delete this.topupPayload.customMerchantId
      } else if (this.topupType === 'static-amount') {
        url = '/va/generate/closed'

        delete this.topupPayload.customMerchantId
      }

      try {
        this.loading = true
        const response = await api_prod.post(url, this.topupPayload)

        this.setVA({
          amount: this.topupPayload.amount ?? 0,
          bankHolder: response.data.data.accountNo,
          bankAccount: this.topupPayload.displayName,
          description: this.topupPayload.description,
          finalAmount: response.data.data.finalAmount,
          adminFee: response.data.data.adminFee ?? 0,
          phone: this.topupPayload.msisdn,
          bank: response.data.data.bankShortCode,
          referenceId: response.data.data.referenceId,
        })
        this.loading = false
        this.resetTopup()

        this.modals.topup = false
        this.modals.vaSuccess = true
      } catch (error) {
        alert(error)
      }
      this.loading = false
    },
    async cashoutHandler() {
      this.loading = true
      const payload = {
        amount: this.cashoutPayload.amount,
        destinationAccountNo: this.account.number,
        bankId: this.account.bankId,
        msisdn: this.cashoutPayload.msisdn
      }

      const withdraw = this.withdraw({body: payload, account: this.account})

      if (withdraw) {
        this.modals.withdraw = false
        this.modals.withdrawSuccess = true
      }
      this.loading = false
    },
    async checkInquiry() {
      try {
        const params = {
          destinationAccountNo: this.account.number,
          bankId: this.account.bankId
        }
        const {data} = await api_prod.post('/cash-out/bank/validations', params)
        const {accountName, bankName, detailAdminFee} = data.data

        this.account.holder = accountName
        // this.account.number = 
        this.cashoutPayload.bankName = bankName
        this.cashoutPayload.adminFee = detailAdminFee.fixFee
      } catch (error) {
        alert('error inquiry', error)
      }
    },
    resetTopup() {
      this.topupPayload = {
        amount: 0,
        bankShortCode: '',
        description: '',
        msisdn: '',
        callbackUrl: 'https://google.com',
        displayName: ''
      }
    },
    resetCashout() {
      this.cashoutPayload = {
        amount: 0,
        msisdn: '',
        bankName: '',
        adminFee: 0,
      }

      this.account = {
        bankId: '',
        number: '',
        holder: ''
      }
    }
  },
  mounted() {
    this.getVABanks()
    this.getCashoutBanks()
  }
};
</script>
