<template>
  <v-card outlined>
    <v-card-text>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>No. Rekening</v-list-item-subtitle>
          <v-list-item-title>{{ withdraw?.bankHolder }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Nama Rekening</v-list-item-subtitle>
          <v-list-item-title>{{ withdraw?.bankAccount }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Transfer Amount</v-list-item-subtitle>
          <v-list-item-title>{{ rupiahFormat(withdraw?.transferedAmount) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Nama Bank</v-list-item-subtitle>
          <v-list-item-title>{{ withdraw?.bankName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Reference ID</v-list-item-subtitle>
          <v-list-item-title>{{ withdraw?.referenceId }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>No. Telepon</v-list-item-subtitle>
          <v-list-item-title>{{ withdraw?.phone }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Admin Fee</v-list-item-subtitle>
          <v-list-item-title>{{ rupiahFormat(withdraw?.adminFee) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Deducted Amount</v-list-item-subtitle>
          <v-list-item-title>{{ rupiahFormat(withdraw?.deductedAmount) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import currency from "@/libs/currency";

export default {
  mixins: [currency],
  computed: {
    ...mapGetters('cashoutModule', ['withdraw'])
  }
}
</script>