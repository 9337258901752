<template>
  <v-card outlined>
    <v-card-text>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>No. VA</v-list-item-subtitle>
          <v-list-item-title>{{ vaSuccess?.bankHolder }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Display Name</v-list-item-subtitle>
          <v-list-item-title>{{ vaSuccess?.bankAccount }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item two-line v-if="vaSuccess?.amount > 0">
        <v-list-item-content>
          <v-list-item-subtitle>Total Topup</v-list-item-subtitle>
          <v-list-item-title>{{ rupiahFormat(vaSuccess?.amount) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Reference ID</v-list-item-subtitle>
          <v-list-item-title>{{ vaSuccess?.referenceId }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Bank</v-list-item-subtitle>
          <v-list-item-title>{{ vaSuccess?.bank }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>No. Telepon</v-list-item-subtitle>
          <v-list-item-title>{{ vaSuccess?.phone }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line v-if="vaSuccess?.adminFee > 0">
        <v-list-item-content>
          <v-list-item-subtitle>Admin Fee</v-list-item-subtitle>
          <v-list-item-title>{{ rupiahFormat(vaSuccess?.adminFee) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Final Amount</v-list-item-subtitle>
          <v-list-item-title>{{ rupiahFormat(vaSuccess?.finalAmount) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Deskripsi</v-list-item-subtitle>
          <v-list-item-title>{{ vaSuccess?.description }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import currency from "@/libs/currency";

export default {
  mixins: [currency],
  computed: {
    ...mapGetters('virtualAccountModule', ['vaSuccess'])
  }
}
</script>